import request from './request'
import { BASE_URL } from './url_config'
const BSET_URL = `app/`
export function getStatisticApp(v,app_name,st,et) {
    return request({
      method: 'GET',
      url: `${BSET_URL}/by_name`,
      baseURL:BASE_URL,
      params:{
        v,
        app_name,
        st,
        et
      }
    })
  }
  
  export function getStatisticAppLine(v,app_name,st,et) {
    return request({
      method: 'GET',
      url: `${BSET_URL}/by_name/line`,
      baseURL:BASE_URL,
      params:{
        v,
        app_name,
        st,
        et
      }
    })
  }
  
  export function getAppList(v,st,et,baseURL=BASE_URL) {
      return request({
        method: 'GET',
        url: `${BSET_URL}/get_app_list`,
        params:{
          v,
          st,
          et
        },
        baseURL
      })
    }
  